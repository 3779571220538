import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { StyledLink } from "./home";
import "react-notion/src/styles.css";
import { NotionRenderer } from "react-notion";
import { useParams } from "react-router-dom";

const ReleaseNotes = () => {
  const { source } = useParams();
  const [blockMap, setBlockMap] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function fetchData() {
    setError(false);
    setLoading(true);
    try {
      const response = await fetch(
        `https://notion-api.splitbee.io/v1/page/7dde4ed6b9774956851abed7cd58c394`
      );
      const data = await response.text();
      setBlockMap(JSON.parse(data));
    } catch (err) {
      setError(true);
      console.log(err);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      {source === "internal" && <StyledLink to="/">Home</StyledLink>}
      {loading && <div>Loading...</div>}
      {error && <div>Something went wrong...</div>}
      {blockMap && <NotionRenderer blockMap={blockMap} />}
      <GlobalStyle />
    </Wrapper>
  );
};

export default ReleaseNotes;

const GlobalStyle = createGlobalStyle`
  body {
    flex-direction: column;
  }

  * {
    text-shadow: none !important;
  }

  a{
    color: black;
  }

  .notion {
    background-color: #ffffff;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
  }

  @media only screen and (max-width: ${(props) =>
    props.theme.mobileBreakpoint}) {
    .notion-asset-wrapper{
      width: auto !important;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
