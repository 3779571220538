import React, { Component } from "react";
import styled from "styled-components";
import { SUPPORT_EMAIL } from "../constants/data";
import logo from "../static/email.svg";
export default class Support extends Component {
  render() {
    return (
      <Button href={`mailto:${SUPPORT_EMAIL}?subject=Support`}>
        <img alt="email" src={logo} height={40} />
        CONTACT US
      </Button>
    );
  }
}

export const Button = styled.a`
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid #fff;
  padding: 0 20px;
  border-radius: 20px;
  transition: 0.3s all linear;
  display: flex;
  align-items: center;
  height: 70px;

  &:hover {
    background-color: #fff;
    color: #4b6cb7;
  }

  img {
    margin-right: 10px;
  }
`;
