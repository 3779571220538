import React, { Component } from "react";
import Box from "../components/box";
import Buttons from "../components/buttons";
import Button from "../components/button";
import ButtonImage from "../components/buttonimage";
import Support from "../components/support";
import Twitter from "../components/twitter";
import { IOS_URL } from "../constants/data";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default class Home extends Component {
  render() {
    return (
      <Box>
        <Buttons>
          <Button href={IOS_URL}>
            <ButtonImage src={require("../static/apple-store-button.png")} />
          </Button>
        </Buttons>
        <Line>
          <Big>iCloud Sync</Big>
          Your date is synced between your devices.
        </Line>
        <Line>
          <Big>Notifications</Big>
          Receive a notification when there is updated info about your tracking
          number.
        </Line>
        <Line>
          <Big>iPad</Big>
          Optimized iPad version.
        </Line>
        <Line>
          <Big>Dark Mode</Big>
          Light and Dark mode.
        </Line>
        <Line>
          <Big>Support</Big>
          We support all major carriers. DHL, FedEx, UPS, GLS, TNT, DPD, USPS...
        </Line>
        <Buttons>
          <Twitter />
          <Support />
        </Buttons>
        <Line>
          <StyledLink to="privacy-policy">Privacy Policy</StyledLink>
          <StyledLink to="release-notes/internal">Release Notes</StyledLink>
        </Line>
      </Box>
    );
  }
}

const Line = styled.div`
  display: flex;
  align-items: baseline;
  margin: 20px 0;

  @media only screen and (max-width: ${({ theme }) => theme.mobileBreakpoint}) {
    flex-direction: column;
  }
`;

const Big = styled.h2`
  font-size: 40px;
  margin: 0;
  padding: 0;
  margin-right: 20px;
  font-weight: bold;
  min-width: 300px;
  text-align: right;

  @media only screen and (max-width: ${({ theme }) => theme.mobileBreakpoint}) {
    text-align: left;
  }
`;

export const StyledLink = styled(Link)`
  margin: 2rem auto;
  color: white;
  font-size: 1.25rem;
`;
