import React, { Component } from "react";
import styled from "styled-components";

export default class Button extends Component {
  render() {
    return (
      <StyledButton
        href={this.props.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {this.props.children}
      </StyledButton>
    );
  }
}

const StyledButton = styled.a`
  flex-grow: 2;
`;
