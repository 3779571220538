import React, { Component } from "react";
import styled from "styled-components";

export default class ButtonImage extends Component {
  render() {
    return (
      <StyledButtonImage src={this.props.src}>
        {this.props.children}
      </StyledButtonImage>
    );
  }
}

const StyledButtonImage = styled.img`
  width: 200px;
`;
