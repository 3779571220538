import React, { Component } from "react";
import styled from "styled-components";

export default class ScrollableBox extends Component {
  render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

const Wrapper = styled.div`
  @import url("https://fonts.googleapis.com/css?family=Raleway");
  font-family: "Raleway", sans-serif;
  background-color: #fff;
  color: #000;
  padding: 20px;
  max-width: 600px;
  margin: 20px;
  box-sizing: border-box;
  overflow: auto;
  max-height: 90vh;
`;
