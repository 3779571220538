import React, { Component } from "react";
import styled from "styled-components";

export default class Buttons extends Component {
  render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

const Wrapper = styled.div`
  display: flex;
  margin: 40px 0 20px 0;
  text-align: center;
  align-items: center;
  justify-content: center;
   
  > * {
     margin: 0 10px;
   }
`;
