import React, { Component } from "react";
import { TWITTER_URL } from "../constants/data";
import { Button } from "./support";
import logo from "../static/twitter.svg";
export default class Support extends Component {
  render() {
    return (
      <Button href={TWITTER_URL} target="_blank">
        <img alt="twitter" src={logo} height={40} />
        Carlos Silva
      </Button>
    );
  }
}
