import React, { Component } from "react";
import styled from "styled-components";
import iphone from "../static/website.png";
export default class Box extends Component {
  render() {
    return (
      <Wrapper>
        <Title>Deliveries Tracker</Title>
        <Top>
          <IphoneImage src={iphone} alt="App running on the smartphone" />
        </Top>
        <Bottom>{this.props.children}</Bottom>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;

  img {
    margin: 20px;
  }
`;

const Bottom = styled.div``;

const IphoneImage = styled.img`
  max-height: 600px;

  @media only screen and (max-width: ${(props) =>
      props.theme.mobileBreakpoint}) {
    max-width: 100%;
    max-height: 800px;
  }
`;

const Title = styled.h1`
  font-size: 80px;
  letter-spacing: 2px;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  margin: 0;

  @media only screen and (max-width: ${(props) =>
      props.theme.mobileBreakpoint}) {
    font-size: 50px;
  }
`;
